.pageNotFound
{
    margin-left: auto;
    margin-right: auto;
    border:2px solid gray;
    padding: 50px;
    margin-top: 150px;
    text-align: center;
    border-radius: 15px;
    background-color:#f8f9fa
}