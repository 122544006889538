.detailPanel {
    color: #f50057;
    background-color: #f8f9fa!important;
    padding: 20px;
    height: inherit;
    width: 600px;
}

.detailPanel_content {
    padding: 20px;
}
.close_avatar {
    color: #f50057 !important;
    background-color: white !important;
    height: 50px;
    width: 50px;
}

.close_avatar_icon {
    width: 50px;
    height: 50px;
}