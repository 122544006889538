.main
{
  margin-left: 400px;
}

.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    padding: 90px 0 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
    z-index: 99;
  }
  
  @media (max-width: 767.98px) {
    .sidebar {
      top: 11.5rem;
      padding: 0;
    }
  }
  
  .navbar {
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .1);
  }
  
  @media (min-width: 767.98px) {
    .navbar {
      top: 0;
      position: sticky;
      z-index: 999;
    }
  }

  .sidebar
  {
    background-color: #262b40!important;
  }
  
  .sidebar .nav-link {
    color: white;
  }
  
  .sidebar .nav-item:hover {
    color: whitesmoke;
    font-weight: 600;
    background-color: #262b40!important;
  }

  .my_footer
  {
    background-color: gainsboro;
  }
  .breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: .5rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #f8f9fa!important;
    border-radius: .25rem;
    border: 1px solid lightgray;
}

.table_row_open
{
  color: cornflowerblue;
}
.table_row_paid
{
  color: cadetblue;
}
.table_row_fail
{
  color: crimson;
}

.table_row_canceled
{
  color: darkgray;
}

.icon_red
{
  color: brown;
}

.icon_green
{
  color: cadetblue;
}


.cadetblue
{
  background-color: cadetblue;
  color:white;
}

.uppercase
{
  text-transform:uppercase
}

.table td,th
{
  background-color: #f8f9fa;
}

a:active, a:checked, a:hover,a
{
  text-decoration: none!important;
  color:black ;
}

a:hover
{
  color: #973B8E;
  font-weight: 500;
}

.transactions_widget
{
  padding: 20px;
  border: 1px solid lightgray;
  background-color: #f8f9fa!important;
    border-radius: .25rem;
}

.form
{
  padding: 20px;
  border: 1px solid lightgray;
  background-color: #f8f9fa!important;
    border-radius: .25rem;
    margin:10px;
}

.detailPanel
{
  right:0 !important;
  left: auto;
}
